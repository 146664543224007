import * as React from 'react';
import './Page.scss';
import { DefaultButton } from '@fluentui/react/lib/Button';
const PageBannerTop = () => {
      
  return ( 
       <div className="pageBannerTop">
            <img src="topbanner.png"/>
            <span className="pageBannerTopText">Design and innovation for modern customer experiences</span>
            <DefaultButton className="requestButton" text="Request Staff" onClick={event =>  window.location.href='/pages/contact'} />
      </div>
      ); 
};

// function onRequestClicked(): void {
//       alert('onRequestClicked');

// }

export default PageBannerTop;