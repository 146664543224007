import * as React from 'react';
import './Page.scss';

const PageBannerPlatforms = () => {
  return ( 
      <div className="pageBannerPlatforms">
                     <span className="title">Technology Platforms</span>
                     <div className="platformBlock">
                            <div className="platform">                            
                                   <img src="/platformAzure.png" alt="Azure"></img>
                            </div>
                            <div className="platform">                            
                                   <img src="/platformGCP.png" alt="GCP"></img>
                            </div>
                            <div className="platform">                            
                                   <img src="/platformAWS.png" alt="AWS"></img>
                            </div>
                            <div className="platform">                            
                                   <img src="/platformSN.png" alt="ServiceNow"></img>
                            </div>
                            <div className="platform">                            
                                   <img src="/platformOkta.png" alt="Okta"></img>
                            </div>
                     </div>
      </div>
      ); 
};

export default PageBannerPlatforms;