import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import './Page.scss';

const PageBannerRecruitment = () => {
  return ( 
      <div className="pageBannerRecruitment">
             <div className="leftPanel">
                     <img src="recruitementCareer.png" alt="Recruitement"/>
             </div>

             <div className="rightPanel">             
                     <DefaultButton className="button buttonFirst" text="Join our team" onClick={event =>  window.location.href='/pages/career'}/>
                     <p>Join our team and you'll take on projects which will sharpen your techical skills and your talent for solving problems.</p>
                     <DefaultButton className="button buttonSecond" text="Explore careers" onClick={joinTeamClicked}/>
             </div>
      </div>
      ); 
};

function joinTeamClicked(): void {
       alert('joinTeamClicked or Explore careers');
}

export default PageBannerRecruitment;