import * as React from 'react';
import PageBannerTop from './Components/PageBannerTop';
import PageBannerNext from './Components/PageBannerNext';
import PageBannerServices from './Components/PageBannerServices';
import PageHeader from './Components/PageHeader';
import PageQuickServices from './Components/PageQuickServices';
import { PageLayoutProps } from './PageLayoutProps';
import PageBannerRecruitment from './Components/PageBannerRecruitment';
import PageBannerPlatforms from './Components/PageBannerPlatforms';
import PageOurServices from './Components/PageOurServices';
import PageContactUs from './Components/PageContactUs';
import PageFooter from './Components/PageFooter';
import {
        BrowserRouter as Router,
        Switch,
        Route,
        Link
      } from "react-router-dom";

const PageLayout = (props: PageLayoutProps) => {
  return ( 
      <div>
        <PageHeader/>
        <PageQuickServices />
        <Switch>
                <Route path="/pages">
                        
                </Route>
                <Route path="/">
                        <PageBannerTop/>    
                        <PageBannerServices/>             
                        <PageBannerNext/>
                        <PageBannerRecruitment/>
                        <PageBannerPlatforms/>
                        <PageOurServices/>   
                        <PageContactUs/>
                </Route>
        </Switch>      
        <PageFooter/>
      </div>
      ); 
};

export default PageLayout;