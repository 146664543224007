import * as React from 'react';
import './Page.scss';

const PageHeaderMenu = () => {
  return ( 
        <div className="pageHeaderMenuRoot">
            <div className="pageHeaderMenu">
                  <div className="pageHeaderMenuItem">
                        <a href="/pages/services">Services</a>
                  </div>

                  <div className="pageHeaderMenuItem">
                        <a href="/pages/industries">Industries</a>
                  </div>

                  <div className="pageHeaderMenuItem">
                        <a href="/pages/insights">Insights</a>
                  </div>

                  <div className="pageHeaderMenuItem">
                        <a href="/pages/career">Career</a>
                  </div>
            </div>

            <select id="selectMenu" className="pageHeaderMenuSmall" onChange={onOptionChange}>
                  <option value="" className="pageHeaderMenuSmallItem">[Select]</option>
                  <option value="/" className="pageHeaderMenuSmallItem" >Home</option>
                  <option value="/pages/services" className="pageHeaderMenuSmallItem" >Services</option>
                  <option value="/pages/industries" className="pageHeaderMenuSmallItem">Industries</option>
                  <option value="/pages/insights" className="pageHeaderMenuSmallItem">Insights</option>
                  <option value="/pages/career" className="pageHeaderMenuSmallItem">Career</option>
            </select>
      </div>
      ); 
};

function onOptionChange():void {

      var menu = document.getElementById("selectMenu") as HTMLSelectElement;      
      if(menu) {
            var url = menu.options[menu.selectedIndex].value;
            if(url!="") {
                  window.location.href = url;
            }
      }
}

export default PageHeaderMenu;