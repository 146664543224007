import * as React from 'react';
import './Page.scss';

const PageOurServices = () => {
  return ( 
       <div className="pageOurServices">
             <span className="title">Our Services</span>
             <div className="panels">
                     <div className="leftPanel">
                            <span className="aboutServicesText">// Text about our services</span>
                     </div>
                     <div className="rightPanel">
                           <img src="ourServices.png" alt="Our services"/>
                     </div>
              </div>
      </div>
      ); 
};

export default PageOurServices;