import * as React from 'react';
import PageQuickServicesBecomeClient from './PageQuickServicesBecomeClient';
import { PageQuickServicesService } from './PageQuickServicesService';
import './Page.scss';

const PageQuickServices = () => {
  return ( 
       <div className="pageQuickServices">
             <div className="leftPanel">
                     <PageQuickServicesService serviceName="Direct Placement" imgSrc="/direct-placement.png" linkUrl="/pages/direct-placement" />
                     <PageQuickServicesService serviceName="Contract & CTH" imgSrc="/contract-cth.png" linkUrl="/pages/contract-cth" />
                     <PageQuickServicesService serviceName="IT Consulting" imgSrc="/it-automations.png" linkUrl="/pages/it-automations" />
                     <PageQuickServicesService serviceName="Apps & Services" imgSrc="/apps-services.png" linkUrl="pages/apps-services" />
                     <PageQuickServicesService serviceName="Cloud Migrations" imgSrc="/cloud-migrations.png" linkUrl="/pages/cloud-migrations" />
                     <PageQuickServicesService serviceName="IT Automations" imgSrc="/it-consulting.png" linkUrl="pages/it-consulting" />
              </div>
      </div>
      ); 
};

{/* Become a client
       <div className="rightPanel">
<PageQuickServicesBecomeClient/>
</div> */}


export default PageQuickServices;