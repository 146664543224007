import * as React from 'react';
import './Page.scss';

const PageFooterMenu = () => {
  return ( 
       <div className="pageFooterMenu">              
             <div className="items">
                <div className="logoFooter"> 
                        <img src="/logoBlack.png"/>
                </div>
                <a href=".">About Us</a>
                <a href=".">Careers</a>
                <a href=".">Contact Us</a>
             </div>
      </div>
      ); 
};

export default PageFooterMenu;