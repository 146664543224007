import * as React from 'react';
import PageFooterMenu from './PageFooterMenu';
import './Page.scss';

const PageFooter = () => {
  var year = new Date().getFullYear();

  return ( 
       <div className="pageFooter">
              
             <PageFooterMenu/>

             <div className="pageFooterCopyrights">
                  <span className="Copyright">Copyright © {year} Orgworking</span>
             </div>
      </div>
      ); 
};

{/* <a href=".">Terms of use</a> */}
{/* <a href=".">Sitemap</a> */}

export default PageFooter;