import * as React from 'react';
import './Page.scss';

const PageHeaderLogo = () => {
  return ( 
       <div className="pageHeaderLogo">
              <a href="" className="logo"> 
                     <img src="/logo.png"/>             
              </a>
      </div>
      ); 
};

export default PageHeaderLogo;