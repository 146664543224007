import * as React from 'react';
import './Page.scss';

const PageBannerNext = () => {
  return ( 
       <div className="pageBannerNext">
             <div className="pageBannerNextLeft">                         
             </div>
             <div className="pageBannerNextRight">            
                    <span className="nextText">Next</span>
                    <a className="whoWeAre" href="/pages/about">Who we are</a>
             </div>
      </div>
      ); 
};

export default PageBannerNext;