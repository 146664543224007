import * as React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import './Page.scss';

const PageContactUs = () => {
  return ( 
       <div className="pageContactUs">
             <div className="leftPanel">            
                     <span className="title">Talk to an expert today</span>
             </div>
             <div className="rightPanel">            
                     <DefaultButton className="contactUsButton" text="Contact Us" onClick={event => window.location.href='/pages/contact'} />
             </div>
      </div>
      ); 
};


// function contactUsClicked(): void {
//        alert('contactUsClicked');
// }     

export default PageContactUs;