import * as React from 'react';
import './Page.scss';

const PageBannerServices = () => {
  return ( 
       <div className="pageBannerServices">
             <div className="pageBannerServicesItem">            
                    <img src="security.png" alt="zero trust endpoints"/>
                    <a className="itemTitle" href=".">Zero Trust on the Endpoint</a>
             </div>
             <div className="pageBannerServicesItem">            
                    <img src="bannerServicesFuture.png" alt="zero trust endpoints"/>
                    <a className="itemTitle" href=".">Powering the Future</a>
             </div>
             <div className="pageBannerServicesItem">            
                    <img src="bannerServicesCloud.png" alt="zero trust endpoints"/>
                    <a className="itemTitle" href=".">Modern cloud solutions</a>
             </div>
      </div>
      ); 
};

export default PageBannerServices;