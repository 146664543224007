import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PageLayout from './App/Pages/PageLayout';
import { BrowserRouter as Router } from 'react-router-dom';

            
ReactDOM.render(        
    <Router>
        <PageLayout title="Orgworking" />
    </Router>
    ,
    document.getElementById('root') as HTMLElement
    , () => {});