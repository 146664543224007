import * as React from 'react';
import { PageQuickServicesServiceProps } from './PageQuickServicesServiceProps';
import './Page.scss';

export class PageQuickServicesService extends React.Component<PageQuickServicesServiceProps> {
  public render() {
    return ( 
        <div className="pageQuickServicesService">
            <a href={this.props.linkUrl}>              
              <img src={this.props.imgSrc} alt={this.props.serviceName}></img>
              {this.props.serviceName}
            </a>
        </div>
        ); 
  }
};