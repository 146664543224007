import * as React from 'react';
import PageHeaderCall from './PageHeaderCall';
import PageHeaderLogin from './PageHeaderLogin';
import PageHeaderLogo from './PageHeaderLogo';
import PageHeaderMenu from './PageHeaderMenu';
import './Page.scss';

const PageHeader = () => {
  return ( 
      <div className="pageHeader">
             <PageHeaderLogo/>
             <PageHeaderMenu/>
      </div>
      ); 
};

{/* TODO: Call and login
       <PageHeaderCall/>
<PageHeaderLogin/>              */}

export default PageHeader;